<template>
    <div class="main-panel no-highlight">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 middle-center">
            <div class="bg-white shadow-lg py-5 px-4 px-sm-5 px-md-5 m-4">
                <div class="h3 mb-2">
                    <i class="fas fa-key me-1"></i>
                    {{$t('title')}}
                </div>
                <div class="text-muted small">{{$t('subtitle')}}</div>
                <div class="my-5">
                    <div v-for="(form, key) in forms" :key="key">
                        <div class="input-box py-2 mt-3 mb-2 overflow-hidden" :class="{'warning': form.warning.status}">
                            <span v-if="form.warning.status">
                                <i :class="form.icon" class="fa-lg me-3 warning fix-width"></i>
                            </span>
                            <span v-else>
                                <i :class="form.icon" class="fa-lg me-3 text-grey fix-width"></i>
                            </span>
                            <input v-model="form.value" @focus="setDefault(form)" :type="form.type" :placeholder="form.placeholder" class="input flex-grow-1 fw-light">
                        </div>
                        <div v-if="form.warning.status" class="d-flex align-items-center justify-content-end warning small">
                            <i class="fas fa-info fa-xs me-2"></i>
                            {{$t(form.warning.message)}}
                        </div>
                    </div>
                </div>
                <div v-if="checkingAuth" class="text-center">
                    <div class="spinner-border spinner-custom" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-3">
                        <router-link :to="{name: 'Homepage'}" custom v-slot="{ navigate }">
                            <div @click="navigate" class="btn-custom btn-exit py-3">
                                <i class="fas fa-home fa-lg"></i>
                            </div>
                        </router-link>
                    </div>
                    <div class="col">
                        <div @click="signIn" class="btn-custom btn-signin py-3">
                            {{$t('signInButton')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "title": "Sign In",
        "subtitle": "Sign-in with your email and password.",
        "connect": "connect",
        "warning": {
            "error": "Something went wrong.",
            "nullEmail": "Email is missing.",
            "nullPassword": "Password is missing.",
            "notFoundEmail": "User not found.",
            "wrongPassword": "Password is not correct.",
            "overRequest": "Please try again later."
        },
        "signInButton": "Sign In"
    },
    "th": {
        "title": "เข้าสู่ระบบ",
        "subtitle": "เข้าสู่ระบบด้วยอีเมลและรหัสผ่านของคุณ",
        "connect": "เชื่อมต่อ",
        "warning": {
            "error": "เกิดข้อผิดพลาด",
            "nullEmail": "ไม่ได้ใส่อีเมล",
            "nullPassword": "ไม่ได้ใส่รหัสผ่าน",
            "notFoundEmail": "ไม่พบบัญชีผู้ใช้",
            "wrongPassword": "รหัสผ่านไม่ถูกต้อง",
            "overRequest": "กรุณาลองใหม่ในภายหลัง"
        },
        "signInButton": "เข้าสู่ระบบ"
    }
}
</i18n>
<style scoped>
.main-panel {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background: linear-gradient(-45deg, #1F4591, #061E47);
    position: relative
}
.input-box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000025;
    transition: .25s;
}
.input {
    border: 0;
    color: inherit;
}
.input:focus {
    outline: none;
}
.fix-width {
    min-width: 2rem;
    max-width: 3rem;
}
.warning {
    border-color: #B22222;
    color: #B22222;
}
.spinner-custom {
    color: #0a3072;
    width: 4em;
    height: 4em;
}
.btn-custom {
    transition: .4s;
    cursor: pointer;
    text-align: center;
}
.btn-custom:active {
    transform: scale(0.9125);
}
.btn-exit {
    background: #2e529b;
    color: white;
}
.btn-exit:hover {
    background: #1d3463;
}
.btn-signin {
    background: #0a3072;
    color: white;
}
.btn-signin:hover {
    background: #061E47;
}
</style>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
    data() {
        return {
            checkingAuth: false,
            forms: {
                email: {
                    type: "email",
                    placeholder: "user@email.com",
                    icon: "fas fa-mail-bulk",
                    value: null,
                    warning: {
                        status: false,
                        message: null
                    }
                },
                password: {
                    type: "password",
                    placeholder: "password",
                    icon: "fas fa-unlock-alt",
                    value: null,
                    warning: {
                        status: false,
                        message: null
                    }
                }
            }
        }
    },
    methods: {
        async signIn() {
            let email = this.forms.email.value
            let password = this.forms.password.value
            if (this.notNullInput(email, password)) {
                try {
                    this.checkingAuth = true
                    const persistenceType = firebase.auth.Auth.Persistence.SESSION
                    await firebase.auth().setPersistence(persistenceType).catch(err => {})
                    await firebase.auth().signInWithEmailAndPassword(email, password)
                    this.$router.push({name: 'Homepage'})
                } catch (err) {
                    const emailInput = this.forms.email
                    const pwdInput = this.forms.password
                    let emailMessage, pwdMessage
                    switch (err.code) {
                        case "auth/invalid-email":
                            emailMessage = "warning.invalidEmail"
                            break;
                        case "auth/user-not-found":
                            emailMessage = "warning.notFoundEmail"
                            break;
                        case "auth/wrong-password":
                            pwdMessage = "warning.wrongPassword"
                            break;
                        case "auth/too-many-requests":
                            emailMessage = "warning.overRequest"
                            break;
                        default:
                            emailMessage = pwdMessage = "warning.error"
                    }
                    if (emailMessage) this.setWarning(emailInput, emailMessage)
                    if (pwdMessage) this.setWarning(pwdInput, pwdMessage)
                }
                this.checkingAuth = false
            }
        },
        notNullInput(email, password) {
            if (email && password) return true
            if (!email) this.setWarning(this.forms.email, "warning.nullEmail")
            if (!password) this.setWarning(this.forms.password, "warning.nullPassword")
            return false
        },
        setWarning(form, message="warning.error") {
            form.warning.status = true
            form.warning.message = message
        },
        setDefault(form) {
            form.warning.status = false
            form.warning.message = null
        }
    }
}
</script>